<template>
  <div class="new-offers">
    <div class="container">
      <BaseAdvancedFilter
        :items="categoriesList"
        :subItems="subList"
        class="p-y-40"
        @switchOffers="switchedToIndex"
        @switchSubOffers="switchedToSubIndex"
        @updatedCity="changeCityId"
        @updatePrice="changePriceArrange"
      />

      <div class="p-y-30">
        <div
          v-if="offerLoading"
          class="row"
        >
          <offerCardLoading
            v-for="item in 3"
            :key="item"
            class="col-12 col-md-6 col-lg-4"
          />
        </div>

        <div
          v-if="cards.length"
          class="row"
        >
          <div
            v-for="(card, index) in cards"
            :key="index"
            class="col-12 col-md-6 col-lg-4"
          >
            <BaseOfferCard
              :singleCardInfo="card"
              @setIdInConsole="setOfferId"
            />
          </div>
        </div>

        <!---->
        <div v-else-if="!cards.length && !offerLoading">
          <h1 class="text-center">
            {{ $t('offers.noOffers') }}
          </h1>
        </div>

        <!---->
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <base-button
        v-if="!loadMore"
        class="text-center m-b-20 m-x-auto"
        :text="$t('home.LoadMoreOffers')"
        is-medium
        fixed-width="160px"
        @click.native="LoadMoreOffers"
      />
    </div>

    <infinite-loading
      v-if="loadMore"
      @infinite="getAllOffers"
    />
  </div>
</template>

<script>
import BaseOfferCard from '@/components/partials/base-offer-card/Index.vue'
import BaseAdvancedFilter from '@/components/partials/base-advanced-filter/Index.vue'
import bestOffersApi from '../../services/home'
import offersApi from '../../services/offers'
import offerCardLoading from '../partials/base-offer-card/loading.vue'
import globalVars from '../../utils/staticVariables'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'NewOffers',
  components: {
    BaseOfferCard,
    BaseAdvancedFilter,
    offerCardLoading,
    InfiniteLoading
  },

  data () {
    return {
      offerLoading: true,
      cards: [],
      subList: [],
      offerPayload: {},
      categoriesList: [],
      parentCategoryValue: '',
      subCategoryValue: [],
      bestSeller: globalVars.option,
      cityId: globalVars.globalCityId,
      priceId: '',
      page: 1,
      paginationStatus: true,
      loadMore: false
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },

  watch: {
    '$route.query': {
      handler () {
        this.parentCategoryValue = this.$route.query.categoryId ? this.$route.query.categoryId : ''
        this.cityId = this.$route.query.city ? this.$route.query.city : globalVars.globalCityId
        this.priceId = this.$route.query.price ? this.$route.query.price : ''
        this.subCategoryValue[0] = this.$route.query.subId ? this.$route.query.subId : ''
      },
      immediate: true
    },

    subCategoryValue () {
      this.resetOffers()
      this.getAllOffers()
    },

    parentCategoryValue () {
      this.resetOffers()
      this.getSectionOffersPage()
    }
  },

  created () {
    if (this.$route.query.categoryId) {
      this.getSubCategories()
    }

    this.getParentCategory()
    this.getAllOffers()

    // fore user when refresh page ... to go to the top of page
    window.onbeforeunload = function () {
      window.scrollTo(0, 0)
    }
  },

  methods: {
    // function will be used in more than one place
    resetOffers () {
      this.cards = []
      this.page = 1
      this.paginationStatus = true
    },

    // when click in any price range ... filter from low to hight price then change offers
    changePriceArrange (id) {
      this.priceId = id

      // reset cards to start filter with new pagination
      // this.resetOffers()
      this.resetOffers()

      // when change price range call get all offers to reload in the page
      this.getAllOffers()

      this.$router
        .push({
          query: {
            ...this.$route.query,
            price: this.priceId
          }
        })
        .catch((err) => {})
    },

    // when click in any city change city id from api to change offers
    changeCityId (id) {
      this.cityId = id

      // reset cards to start filter with new pagination
      this.resetOffers()

      // when change city call get all offers to reload in the page
      this.getAllOffers()

      this.$router
        .push({
          query: {
            ...this.$route.query,
            city: this.cityId
          }
        })
        .catch((err) => {})
    },

    // to route me to single offer page
    setOfferId (id, name) {
      const offerName = name.replace(/[ "" 25%]/g, '-')
      const path = {
        name: 'offer-details',
        params: { id, offerName }
      }
      if (this.$route.path !== path) this.$router.push(path)
    },

    // at start of viewing window get all offers
    getAllOffers ($state) {
      this.offerPayload = {
        option: this.priceId,
        section_id: this.parentCategoryValue ? this.parentCategoryValue : globalVars.option.hot_deals,
        sub_section_id: this.subCategoryValue,
        city: this.cityId,
        page: this.page,
        platform: 'web',
        limit: 9
      }
      if (this.isLoggedIn) {
        this.offerPayload = {
          ...this.offerPayload,
          token: this.getUserToken
        }
      } else {
        this.offerPayload = {
          ...this.offerPayload
        }
      }

      if (this.paginationStatus) {
        this.offerLoading = true

        offersApi.filterOffers(this.offerPayload).then((res) => {
          this.offerLoading = false

          if (res.data.offers) {
            this.paginationStatus = res.data.moreData

            this.cards.push(...res.data.offers)

            if (this.paginationStatus) {
              this.page += 1
              this.loadMore = false
            }
          }
        })
      }
    },

    LoadMoreOffers () {
      this.loadMore = true
      this.getAllOffers
    },

    // to get parent category items
    getParentCategory () {
      const payload = {
        app_version: '',
        store: ''
      }

      bestOffersApi.getOffersCategory(payload).then((res) => {
        this.categoriesList = res.data.sections.map((item) => {
          return {
            name: item.section_name,
            id: item.section_id,
            image: item.image,
            background_image: item.background_image,
            background_color: item.background_color
          }
        })
      })
    },

    getSubCategories () {
      // get all sub categories for each parent category
      const payload = {
        app_version: '',
        store: '',
        section_id: this.parentCategoryValue
      }

      offersApi.subListOffers(payload).then((res) => {
        this.subList = res.data.sub_categories.map((item) => {
          return {
            name: item.sub_category_name,
            id: item.sub_id
          }
        })
      })
    },

    // update parent category id
    switchedToIndex (categoryId) {
      // reset value when switching to another category
      this.subCategoryValue = []

      this.parentCategoryValue = categoryId.id

      this.$router
        .push({
          query: {
            ...this.$route.query,
            categoryId: this.parentCategoryValue,
            categoryName: categoryId.name,
            subId: ''
          }
        })
        .catch((err) => {})
    },

    switchedToSubIndex (subItemId) {
      // reset value when switching to another category
      this.subCategoryValue = []

      // update sub category id
      this.subCategoryValue.push(subItemId)

      this.$router
        .push({
          query: {
            ...this.$route.query,
            subId: this.subCategoryValue
          }
        })
        .catch((err) => {})
    },

    getSectionOffersPage () {
      // call sub filter offers to be executed in parent category
      this.getSubCategories()

      // get offers
      this.getAllOffers()
    }
  }
}
</script>
