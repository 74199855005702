<template>
  <div>
    <BestOffers />
  </div>
</template>

<script>
import BestOffers from '@/components/Offers/BestOffers.vue'
export default {
  components: { BestOffers },
  data: () => ({
    title: ''
  })
}
</script>
